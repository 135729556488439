import { useRef, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import { Loading } from '@nextui-org/react';

export default function Home() {
  const ref = useRef(null);
  const navigate = useNavigate();

  let [loading, setLoading] = useState(false);

  useEffect(() => {
    ref.current.continuousStart();
      setTimeout(() => {
        setLoading(!loading);
        navigate("/en-us/")
        ref.current.complete();
      }, 2750);
  });
  
  return (
    <>
      <div className="absolute top-0 right-0 p-5">
          <Loading />
      </div>
      <LoadingBar color="#ffffff" ref={ref} />
    </>
  )
}
