import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'flowbite';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Home,
  MainPage
} from "./pages";
ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/en-us" element={<MainPage />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);