import { SpeakerphoneIcon } from '@heroicons/react/outline'

export default function MainPage() {
    return (
        <>
            <div className="bg-blue-600">
                <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between flex-wrap">
                        <div className="w-0 flex-1 flex items-center">
                        <span className="flex p-2 rounded-lg bg-blue-800">
                            <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                        <p className="ml-3 font-medium text-white truncate">
                            <span className="md:hidden">This messages is for mobile.</span>
                            <span className="hidden md:inline">We are under construction, and we will be back shortly.</span>
                        </p>
                        </div>
                        <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        </div>
                        <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                        <button
                            type="button"
                            className="-mr-1 flex p-2 rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                        >
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="min-h-full">
                    <main>
                    <div className="dark:bg-slate-900 mx-auto py-30 sm:px-6 lg:px-8 ">
                        {/* Introduction */}
                        <div className="px-4 py-6 sm:px-0">
                        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                            <div className="sm:text-center lg:text-center">
                            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                <span className="dark:text-white block xl:inline">Free and Reliable </span>{' '}
                                <span className="block text-blue-500 xl:inline">Hosting</span>
                            </h1>
                            <p className="text-base text-gray-500 sm:text-lg sm:mx-auto md:mt-1 md:text-xl lg:mx-0">
                                We are temporarily closed down for a big release of AlacticHost V3 :)
                            </p>
                            </div>
                        </main>
                        </div>
                        {/* FAQs */}
                        <div className="px-4 py-6 sm:px-0">
                        <div className="py-12 dark:bg-slate-900">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="lg:text-center">
                                <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase">FAQs</h2>
                                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                                We answered some of your questions
                                </p>
                                <br></br>
                                <div id="accordion-collapse" data-accordion="collapse">
                                <h2 id="accordion-collapse-heading-4">
                                    <button type="button" class="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                    <span>What happened to AlacticHost?</span>
                                    <svg data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    </button>
                                </h2>
                                <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                                    <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                                    <p class="mb-2 text-gray-500 dark:text-gray-400">We don't have enough funds, and the Discord server was stolen. We are currently working hard to rebuild the hosting, and we kindly ask that you leave the previous Discord server as we have lost control. As a result, we will not repeat the same mistake in the next release. </p>
                                    </div>
                                </div>


                                <h2 id="accordion-collapse-heading-5">
                                    <button type="button" class="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                    <span>What will be new in the next release?</span>
                                    <svg data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    </button>
                                </h2>
                                <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
                                    <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                                    <p class="mb-2 text-gray-500 dark:text-gray-400">Custom dashboard, increased server resources (up to 4GB per server), this is likely to be the largest free hosting service ever created.</p>
                                    </div>
                                </div>

                                <h2 id="accordion-collapse-heading-6">
                                    <button type="button" class="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                    <span>When will it be released again?</span>
                                    <svg data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    </button>
                                </h2>
                                <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
                                    <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                                    <p class="mb-2 text-gray-500 dark:text-gray-400">There is no ETA, but I expect it to be a few months because I am working hard to raise funds to keep the hosting going. All you have to do is be patient, and we will (hopefully) notify all existing customers about the new release. </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
      </>
    )
}